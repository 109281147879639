(function($) {
    $(() => {

        $("#info-toggle").change(function(event){
            event.preventDefault();
        
            let info = $('#info-toggle').is(':checked');
            let _token = $('meta[name="csrf-token"]').attr('content');
        
            $.ajax({
              url: "/info-ajax-request",
              type:"POST",
              data:{
                info: info,
                _token: _token
              },
              success:function(response){
                    if (response.value == 'true') {
                        $('.page-content').removeClass('hidden-info');
                    } else {
                        $('.page-content').addClass('hidden-info');
                    }
              },
              error: function(error) {
               console.log(error);
              }
             });
        });

    });
})(jQuery);
