(function($) {
    $(() => {

        var hash = location.hash.replace(/^#/, '');  // ^ means starting, meaning only match the first hash
        if (hash) {
            $('.nav-tabs a[href="#' + hash + '"]').tab('show');
        } 
        
        // Change hash for page-reload
        // $('.nav-tabs a').on('shown.bs.tab', function (e) {
        //     window.location.hash = e.target.hash;
        // });

    });
})(jQuery);
