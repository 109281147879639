(function($) {
    $(() => {

        $('.form-group:not(.form-group--no-anim) input.form-control').focus(function(){
            $(this).parents('.form-group').addClass('is-focused');
        });

        $('.form-group:not(.form-group--no-anim) input.form-control').focusout(function(){
            $(this).parents('.form-group').removeClass('is-focused');
        });

        $('.form-group .trigger').click(function(){
            $(this).parents('.form-group').find('input').trigger('click');
        });

        $("input[type=file]").change(function (){
            var fileName = $(this).val();
            $(this).parents('.form-group').find('.trigger').html(fileName).css('color','white');
        });

        $("#type_id select").change(function (){
            if($(this).val() == 0) {
                $('.hidden-input').slideDown(200);
                $('.hidden-input').focus();
            } else {
                $('.hidden-input').slideUp(200);
            }
        });

        $('.js-delete-image').click(function(){
            $(this).parents('.form-group').find('img').slideUp(500);
            setTimeout(function(){
                $(this).parents('.form-group').find('img').remove();
            },500);
        });

    });
})(jQuery);
