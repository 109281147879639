'use strict';

function countUpFromTime(countFrom, id) {
    countFrom = new Date(countFrom).getTime();
    var now = new Date(),
        countFrom = new Date(countFrom),
        timeDifference = (now - countFrom);

    var secondsInADay = 60 * 60 * 1000 * 24,
        secondsInAHour = 60 * 60 * 1000;

    var days = Math.floor(timeDifference / (secondsInADay) * 1);
    var hours = Math.floor((timeDifference % (secondsInADay)) / (secondsInAHour) * 1);
    var mins = Math.floor(((timeDifference % (secondsInADay)) % (secondsInAHour)) / (60 * 1000) * 1);
    var secs = Math.floor((((timeDifference % (secondsInADay)) % (secondsInAHour)) % (60 * 1000)) / 1000 * 1);

    // $('.days').html(days);

    $('.hours').html(hours);
    if (hours > 0) {
        $('.hours, .timeRefHours').show();
    } else {
        $('.hours, .timeRefHours').hide();
    }

    $('.minutes').html(mins);
    if (mins > 0) {
        $('.minutes, .timeRefMinutes').show();
    } else {
        $('.minutes, .timeRefMinutes').hide();
    }

    $('.seconds').html(secs);
    if (secs > 0) {
        $('.seconds, .timeRefSeconds').show();
    } else {
        $('.seconds, .timeRefSeconds').hide();
    }

    clearTimeout(countUpFromTime.interval);
    countUpFromTime.interval = setTimeout(function(){ countUpFromTime(countFrom, id);}, 1000);
}

function closeModal() {
    $('.navbar-toggler').removeClass('is-active');
    $('.sidebar-container').removeClass('is-opened');
    $('.page-content').removeClass('navbar-opened');
}

(function($) {
    $(function() {

        $('.js-switch-label span').click(function(){
            $(this).parents('.js-switch-label').find('span').addClass('default');
            $(this).removeClass('default');
        });

        $('#js-wysiwyg').summernote({
            codemirror: { // codemirror options
                theme: 'darkly'
            }
        });

        $('.accordion i').click(function(){
            $(this).parents('.accordion').next('ul').slideToggle(200);
            $(this).parents('.accordion').toggleClass('is-opened');
        });

        $('.js-btn-animate').click(function(){
            var button = $(this);
            button.addClass('is-clicked');
            setTimeout(function(){
                button.removeClass('is-clicked');
            },2000);
        });

        $('.js-admin-seed').click(function(e){
            var conf = confirm("Tuti?");
            if (conf == true) {
                $('.js-admin-seed-form').submit();
            }
        });

        $('.js-admin-migrate').click(function(e){
            var conf = confirm("Tuti?");
            if (conf == true) {
                $('.js-admin-migrate-form').submit();
            }
        });

        $('.page-content').click(function (e) {
            if(e.pageX > $('.sidebar').outerWidth() && $('.page-content').hasClass('navbar-opened')) {
                closeModal();
            }
        });

        // CLOSE MODALS ON ESC PRESSED
        $(document).keyup(function(e) {
            if (e.keyCode == 27) {
                closeModal();
                $('.exercise').removeClass('is-shown');
            }
        });

    });
})(jQuery);
