(function($) {
    $(() => {

        $('input[name=s]').keyup(function(){
            
            if($(this).val().length > 0) {
                $('.form-group--admin-search .clear').addClass('is-shown');
            } else {
                $('.form-group--admin-search .clear').removeClass('is-shown');
            }

        });

    });
})(jQuery);
