function filter() {
	let row = $('#row-to-show select').val();
	let date = $('.monthpicker').val();
	date = date.split('. ');        	

	let query = [];

	query.push('row=' + row);
	if ($('.monthpicker').val() != '') {
		query.push('year=' + date[0] + '&month=' + date[1]);
	}
	let queryString = query.join('&');
	
	window.location.href = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + queryString;
} 

(function($) {
    $(() => {

    	var monthToNumber = [];
		monthToNumber['Január'] = 1;
		monthToNumber['Február'] = 2;
		monthToNumber['Március'] = 3;
		monthToNumber['Április'] = 4;
		monthToNumber['Május'] = 5;
		monthToNumber['Június'] = 6;
		monthToNumber['Július'] = 7;
		monthToNumber['Augusztus'] = 8;
		monthToNumber['Szeptember'] = 9;
		monthToNumber['Október'] = 10;
		monthToNumber['November'] = 11;
		monthToNumber['December'] = 12;

    	$('input.monthpicker').monthpicker({
			monthNames: ['Január','Február','Március','Április','Május','Június', 'Július','Augusztus','Szeptember','Október','November','December'],
			monthNamesShort: ['Január','Február','Március','Április','Május','Június', 'Július','Augusztus','Szeptember','Október','November','December'],
			showOn: "both",
			buttonImage: "/img/calendar-alt-light.svg",
			buttonImageOnly: true,
			changeYear: true,
			dateFormat: 'yy. M.'
		});

        $('#deleted').change(function(){
			$('.deleted-row').toggle();
        });
		
        $('#user-filter').change(function(){
			filter();
        });
		
        $('#row-to-show select').change(function(){
			filter();
        });

        $('.monthpicker').change(function(){
        	var date = $('input.monthpicker').val();
        	date = date.split('.');

        	if($('#user-filter option:selected').val() == -1) {
        		window.location.href = window.location.protocol + '//' + window.location.host + window.location.pathname + '?year=' + date[0] + '&month=' + monthToNumber[date[1].trim()];
        	} else {
				let user = $('#user-filter').length > 0 ? 'user=' + $('#user-filter option:selected').val() + '&' : '';
        		window.location.href = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + user + 'year=' + date[0] + '&month=' + monthToNumber[date[1].trim()];
        	}
        });

    });
})(jQuery);
