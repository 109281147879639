(function($) {
    $(() => {

        $('.js-tooltip-click').click(function(){
            $('.tooltip').removeClass('is-shown');
            $('.tooltip',this).addClass('is-shown');
        });

        $('.js-tooltip-hover').mouseover(function(){
            $('.tooltip').removeClass('is-shown');
            $('.tooltip',this).addClass('is-shown');
        }).mouseleave(function(){
            $('.tooltip').removeClass('is-shown');
        });

        $(document).mouseup(function(e) {
            var container = $('.tooltip');
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $('.tooltip').removeClass('is-shown');
            }
        });

    });
})(jQuery);
